<template>
    <transition name="slide-fade">
        <div v-show="value"
            class="side-menu"
            :class="{
                'side-menu-active': value
            }">
            <div class="menu-content-wrapper">
                <div class="side-menu-brand">
                    <a class="menu-brand-content" href="http://www.insuresavvy.com.my" target="_blank">
                        <img src="/static/logos/insuresavvy-logo-40.png" alt="">
                    </a>
                </div>

                <ul class="side-menu-list" v-if="isSecurityPhraseSet">
                    <template v-if="isAdmin || isCompanyAccount || isUser">
                        <li v-for="(menuItem, index) in displayMenuList"
                            :key="index"
                            class="menu-list-item"
                            :class="menuItem.route ? `sideMenu-menuItem-${menuItem.route}` : ''">
                            <template v-if="menuItem.children && !menuItem.affiliate">
                                <div class="list-item-group">
                                    <div class="list-item-content list-item-collapse-header" @click="menuGroupClicked">
                                        <div class="menu-active-indicator"></div>
                                        <div class="menu-item-icon">
                                            <i class="fa"
                                                :class="menuItem.icon">
                                            </i>
                                        </div>
                                        <div class="menu-item-title">{{menuItem.title}}</div>
                                        <div class="menu-item-action"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
                                    </div>
                                    <div class="list-item-collapsible">
                                        <template v-for="(submenuItem, index) in menuItem.children">
                                            <template v-if="!submenuItem.admin && !submenuItem.affiliate ? true : (isAdmin && submenuItem.admin ? true : (isAffiliate && submenuItem.affiliate ? true : false))">
                                                <router-link :key="index"
                                                    class="list-item-content list-item-group-item"
                                                    :to="{'name': submenuItem.route}">
                                                    <div class="menu-active-indicator"></div>
                                                    <div class="menu-item-icon">
                                                        <i class="fa"
                                                            :class="submenuItem.icon">
                                                        </i>
                                                    </div>
                                                    <div class="menu-item-title">{{submenuItem.title}}</div>
                                                    <!-- <div class="menu-item-action"></div> -->
                                                </router-link>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <router-link v-if="(!isCompanyAccount || (isCompanyAccount && !menuItem.notForCompany)) && !menuItem.affiliate" class="list-item-content"
                                    :to="{'name': menuItem.route}">
                                    <div class="menu-active-indicator"></div>
                                    <div class="menu-item-icon">
                                        <i class="fa"
                                            :class="menuItem.icon">
                                        </i>
                                    </div>

                                    <div class="menu-item-title">{{isCompanyAccount ? (menuItem.titleForCompany? menuItem.titleForCompany: menuItem.title) : menuItem.title}}</div>
                                    <div class="menu-item-action"></div>
                                </router-link>
                            </template>
                        </li>
                    </template>
                    <template v-if="isAffiliate">
                        <li v-for="(menuItem, index) in displayMenuList"
                            :key="index"
                            class="menu-list-item"
                            :class="menuItem.route ? `sideMenu-menuItem-${menuItem.route}` : ''">
                            <template v-if="menuItem.children && menuItem.affiliate">
                                <div class="list-item-group">
                                    <div class="list-item-content list-item-collapse-header" @click="menuGroupClicked">
                                        <div class="menu-active-indicator"></div>
                                        <div class="menu-item-icon">
                                            <i class="fa"
                                                :class="menuItem.icon">
                                            </i>
                                        </div>
                                        <div class="menu-item-title">{{menuItem.title}}</div>
                                        <div class="menu-item-action"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
                                    </div>
                                    <div class="list-item-collapsible">
                                        <template v-for="(submenuItem, index) in menuItem.children">
                                            <template v-if="submenuItem.admin ? isAdmin ? true : false : true">
                                                <router-link :key="index"
                                                    class="list-item-content list-item-group-item"
                                                    :to="{'name': submenuItem.route}">
                                                    <div class="menu-active-indicator"></div>
                                                    <div class="menu-item-icon">
                                                        <i class="fa"
                                                            :class="submenuItem.icon">
                                                        </i>
                                                    </div>
                                                    <div class="menu-item-title">{{submenuItem.title}}</div>
                                                    <div class="menu-item-action"></div>
                                                </router-link>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </li>
                    </template>
                    <li v-show="!isPublic" class="menu-list-item">
                        <a href="javascript: void(0);" class="list-item-content" @click="logoutUser">
                            <div class="menu-active-indicator"></div>
                            <div class="menu-item-icon">
                                <i class="fa fa-sign-out">
                                </i>
                            </div>
                            <div class="menu-item-title">Logout</div>
                            <div class="menu-item-action"></div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    'name': 'SideMenu',
    'props': {
        'value': {
            'type': Boolean,
            'default': true
        }
    },
    'computed': {
        menuList () {
            return this.$store.getters.availableSideMenuList;
        },
        isPublic () {
            return this.$store.state.isPublic;
        },
        displayMenuList () {
            if (this.isPublic) {
                return this.menuList.filter((item) => item.public);
            } else {
                return this.menuList.filter((item) => item.admin === this.isAdmin);
            }
        },
        isAdmin () {
            return this.$store.state.user.userRole === 1;
        },
        isCompanyAccount () {
            return this.$store.state.user.userRole === 2;
        },
        isUser () {
            return this.$store.state.user.userRole === 3;
        },
        isAffiliate () {
            return this.$store.state.user.userRole === 4;
        },
        isSecurityPhraseSet () {
            if (this.isPublic) {
                return true;
            }

            if (!this.isPublic && this.$store.state.user.addedSecurityPhrase === 0) {
                return false;
            }

            return true;
        }
    },
    'watch': {
        displayMenuList (val) {
            this.$store.commit('setSideMenuList', val);

            this.$nextTick(() => {
                this.checkCollapseExpand();
            });
        },
        $route (to, from) {
            this.$nextTick(() => {
                this.checkCollapseExpand();
            });
        }
    },
    mounted () {
        this.$store.commit('setSideMenuList', this.displayMenuList);
    },
    'methods': {
        menuGroupClicked (event) {
            let parentClass = 'list-item-collapse-header';

            let clickedDom = event.target;

            while ((clickedDom = clickedDom.parentElement) && !clickedDom.classList.contains(parentClass));

            if (clickedDom) {
                if (clickedDom.classList.contains('expand')) {
                    clickedDom.classList.remove('expand');
                } else {
                    clickedDom.classList.add('expand');
                }
            }
        },
        checkCollapseExpand () {
            let highlightedDom = document.querySelector('.router-link-active');

            if (highlightedDom) {
                let isGroupItem = highlightedDom.classList.contains('list-item-group-item');

                if (isGroupItem) {
                    let parentWrapper = highlightedDom;
                    let parentWrapperClass = 'list-item-group';

                    while ((parentWrapper = parentWrapper.parentElement) && !parentWrapper.classList.contains(parentWrapperClass));

                    if (parentWrapper) {
                        let expandHeader = parentWrapper.querySelector('.list-item-collapse-header');
                        if (expandHeader) {
                            expandHeader.classList.add('expand');
                        }
                    }
                }
            }
        },
        logoutUser () {
            this.axiosInstance.get('account/logout')
                .finally(() => {
                    this.$store.commit('setAppStatus', true);
                    this.$store.commit('clearUserState');
                    this.$router.push({
                        'name': 'login'
                    });
                });
        }
    }
};
</script>

<style>
:root
{
    --menu-item-height: 48px;
}

.side-menu
{
    position: fixed;
    width: 250px;
    min-height: 100vh;
    height: 100vh;
    padding-bottom: 48px;
    background-color: #2f323a;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.side-menu .side-menu-brand
{
    height: 100px;
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.side-menu .side-menu-brand .menu-brand-content img
{
    display: block;
    max-height: 40px;
    width: auto;
}

.side-menu-list
{
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.side-menu-list .menu-list-item
{
    color: #4c4f58;
    list-style: none;
    background-color: #2f323a;
}

.side-menu-list .menu-list-item .list-item-content
{
    display: flex;
    align-items: center;
    height: 48px;
    padding-right: 16px;
    color: #9499a8;
    transition: 0.2s background-color ease-in-out;
}

.side-menu-list .menu-list-item .list-item-content:hover
{
    background-color: #25282e;
    text-decoration: none;
}

.side-menu-list .menu-list-item .list-item-content .menu-active-indicator
{
    width: 5px;
    height: 48px;
    background-color: transparent;
    transition: 0.2s background-color ease-in-out;
}

.side-menu-list .menu-list-item .list-item-content .menu-item-icon
{
    width: 40px;
    text-align: center;
}

.side-menu-list .menu-list-item .list-item-content .menu-item-title
{
    flex: 1;
    font-size: .9rem;
}

.side-menu-list .menu-list-item .list-item-content .menu-item-action
{
    width: 30px;
    text-align: right;
}

/*Router Link Active Styles*/
.side-menu-list .menu-list-item .list-item-content.router-link-active
{
    background-color: #25282e;
}

.side-menu-list .menu-list-item .list-item-content.router-link-active .menu-active-indicator
{
    background-color: #009344;
}

.side-menu-list .menu-list-item .list-item-content.router-link-active .menu-item-title
{
    color: #fff;
}
/*Router Link Active Styles*/

/*Collapsible*/
.side-menu-list .list-item-collapse-header
{
    cursor: pointer;
    transition: 0.3s background-color ease-in-out;
}

.side-menu-list .list-item-collapse-header.expand
{
    background-color: #25282e;
}

.side-menu-list .list-item-collapse-header .menu-item-action .fa
{
    transition: 0.2s ease-out;
}

.side-menu-list .list-item-collapse-header.expand .menu-item-action .fa
{
    transform: rotate(180deg);
}

.side-menu-list .list-item-collapsible
{
    height: 0px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    background-color: #25282e;
}

.side-menu-list .list-item-collapse-header.expand + .list-item-collapsible
{
    height: auto;
}

.side-menu-list .menu-list-item .list-item-collapsible .router-link-active .menu-active-indicator
{
    background-color: inherit;
}

.side-menu-list .menu-list-item .list-item-collapsible .router-link-active.router-link-exact-active .menu-active-indicator
{
    background-color: #009344;
}

.side-menu-list .list-item-collapsible .menu-list-item .list-item-content.router-link-active
{
    background-color: inherit;
}

.side-menu-list .list-item-collapsible .menu-list-item .list-item-content.router-link-active.router-link-exact-active
{
    background-color: #25282e;
}

.side-menu-list .menu-list-item .list-item-content.router-link-active .menu-item-title
{
    color: inherit;
}

.side-menu-list .menu-list-item .list-item-content.router-link-active.router-link-exact-active .menu-item-title
{
    color: #fff;
}
/*Collapsible*/

/*Transition*/
.slide-fade-enter-active,
.slide-fade-leave-active
{
    transition: all .2s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to
{
    transform: translateX(-250px);
    opacity: 0;
}
/*Transition*/
</style>
