import moment from 'moment';

export function generateRandomId () {
    let randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
    return `${randLetter}${Math.random().toString(36).substr(2, 10)}`;
}

export function splitLines (lines) {
    let processedString = lines.replace(/\n|\r/g, '<br>');
    return processedString;
}

export function validateMultipleEmails (emails) {
    emails = emails.trim();
    if (emails) {
        // trim trailing ;
        emails = emails.replace(/;+$/, '');

        let emailList = emails.split(';');

        let regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return emailList.every((email) => regEx.test(email));
    } else {
        return false;
    }

    // return re.test(email.toLowerCase());
}

export function getShortDate (iso8601DateTimeString) {
    let day = moment(iso8601DateTimeString);
    return day.format('DD/MM/YYYY');
}

// Object
export function getDateFromIso8601 (iso8601DateTimeString) {
    return moment(iso8601DateTimeString).toDate();
}

export function getDateTimeStringFromIso8601 (iso8601DateTimeString) {
    return moment(iso8601DateTimeString).format('DD/MM/YYYY HH:mm:ss');
}

export function getDateStringFromIso8601 (iso8601DateTimeString) {
    return moment(iso8601DateTimeString).format('DD/MM/YYYY');
}

export function convertDateToAspDate (ddmmyyyyString) {
    let splitDates = ddmmyyyyString.split('/');

    return `${splitDates[1]}/${splitDates[0]}/${splitDates[2]}`;
}

export function convertDateToIso8601 (birthdate) {
    let momentInstance = moment(birthdate, 'DD/MM/YYYY');
    return momentInstance.toISOString(true);
}

export function convertDateToYYYYmmdd (ddmmyyyyString) {
    let splitDates = ddmmyyyyString.split('/');

    return `${splitDates[2]}-${splitDates[1]}-${splitDates[0]}`;
}

export function getFirstDayOfMonth () {
    let day = moment();
    return day.format('YYYY-MM') + '-01';
}

export function getFirstDayOfYear () {
    let day = moment();
    return day.format('YYYY') + '-01-01';
}

export function getToday () {
    let day = moment();
    return day.format('YYYY-MM-DD');
}

export function queueStatusDesc (status) {
    if (status === 0) {
        return 'Pending';
    } else if (status === 1) {
        return 'Sending';
    } else if (status === 2) {
        return 'Sent';
    } else {
        return 'Unknown';
    }
}

export function nricToBd (yymmdd) {
    let date = moment(yymmdd, 'YYMMDD');

    if (date.isValid()) {
        let today = moment();

        if (date.isAfter(today)) {
            return date.subtract(100, 'years').format('DD/MM/YYYY');
        } else {
            return date.format('DD/MM/YYYY');
        }
    } else {
        return moment(yymmdd, 'YYMMDD').format('DD/MM/YYYY');
    }
}

export function canShowProfileGuide () {
    return canShowGuide('profile');
}

export function canShowProfile2Guide () {
    return canShowGuide('profile2');
}

export function canShowVehicleGuide () {
    return canShowGuide('vehicle');
}

export function canShowVehicle2Guide () {
    return canShowGuide('vehicle2');
}

export function canShowVehicleFormGuide () {
    return canShowGuide('vehicleForm');
}

export function canShowQuotationGuide () {
    return canShowGuide('quotation');
}

export function canShowQuotationFormGuide () {
    return canShowGuide('quotationForm');
}

export function canShowQuotationIndexGuide () {
    return canShowGuide('quotationIndex');
}

export function canShowWorkshopGuide () {
    return canShowGuide('workshop');
}

export function canShowTelematicsGuide () {
    return canShowGuide('telematics');
}

export function canShowFamilyGuide () {
    return canShowGuide('family');
}

export function dontShowProfileGuide () {
    return dontShowGuide('profile');
}

export function dontShowProfile2Guide () {
    return dontShowGuide('profile2');
}

export function dontShowVehicleGuide () {
    return dontShowGuide('vehicle');
}

export function dontShowVehicle2Guide () {
    return dontShowGuide('vehicle2');
}

export function dontShowVehicleFormGuide () {
    return dontShowGuide('vehicleForm');
}

export function dontShowQuotationGuide () {
    return dontShowGuide('quotation');
}

export function dontShowQuotationFormGuide () {
    return dontShowGuide('quotationForm');
}

export function dontShowQuotationIndexGuide () {
    return dontShowGuide('quotationIndex');
}

export function dontShowWorkshopGuide () {
    return dontShowGuide('workshop');
}

export function dontShowTelematicsGuide () {
    return dontShowGuide('telematics');
}

export function dontShowFamilyGuide () {
    return dontShowGuide('family');
}

export function dontShowGuide (module) {
    let date = new Date();
    date.setMonth(date.getMonth() + 6);

    localStorage.setItem('guide.prevent.' + module, '1');
    localStorage.setItem('guide.prevent.' + module + '.until', date);
}

export function canShowGuide (module) {
    let doNotShow = localStorage.getItem('guide.prevent.' + module);
    let doNotShowUntil = localStorage.getItem('guide.prevent.' + module + '.until');

    if (doNotShow === '1') {
        let doNotShowUntilDate = new Date(doNotShowUntil);

        if (new Date() < doNotShowUntilDate) {
            return false;
        }
    }

    return true;
}

export function getSumAssuredBasisType (value) {
    if (parseInt(value) === 0) {
        return 'Fixed Amount';
    } else {
        return 'Multiple of Salary (Monthly)';
    }
}

export function getCoverageOfGTLAndPPD (value) {
    if (parseInt(value) === 0) {
        return 'All Causes';
    } else {
        return 'Natural Causes';
    }
}

export function getGroupCriticalIllnessType (value) {
    if (parseInt(value) === 0) {
        return 'Additional';
    } else {
        return 'Accelerated';
    }
}

export function formatSumAssuredBasis (sumAssuredBasisType, value) {
    if (parseInt(sumAssuredBasisType) === 0) {
        return `RM${parseFloat(value)}`;
    } else {
        return `x${parseFloat(value)}`;
    }
}

export function formatGroupCriticalIllness (groupCriticalIllnessType, value) {
    if (parseInt(groupCriticalIllnessType) === 0) {
        return `RM${parseFloat(value)}`;
    } else {
        return `${parseFloat(value)}%`;
    }
}

export function getProductName (id) {
    if (!id) {
        return '';
    }

    // Split the string into words at hyphens, removing the hyphens
    const words = id.split('-').map(word => word.trim().charAt(0).toUpperCase() + word.slice(1));

    // Join the words back with spaces
    return words.join(' ');
}

export function getGeneralInsuranceProductName (id) {
    if (id === 'fire') {
        return 'Fire/Property';
    }

    if (id === 'burglary') {
        return 'Fire/Property + Burglary';
    }

    if (id === 'sme') {
        return 'RHB Insurance Fire/Property + SMI Supreme';
    }

    if (id === 'others') {
        return 'Others';
    }
}
