/* global $ */
import { apiPath } from '@/config';
import { validateMultipleEmails } from '@/utils';
import axios from 'axios';

export default {
    beforeRouteLeave  (to, from, next) {
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        $('.modal').modal('hide');
        next();
    },
    'methods': {
        axiosConfig (usingJsonHeader = true) {
            return {
                'baseURL': apiPath,
                'withCredentials': true,
                'headers': {
                    'Content-Type': usingJsonHeader ? 'application/json' : 'application/x-www-form-urlencoded'
                }
            };
        },
        multipleEmailsValidator (value, args) {
            return validateMultipleEmails(value);
        },
        latLongDefaultValidator (value, args) {
            return value !== 0;
        }
    },
    data () {
        return {
            'axiosInstance': null,
            'axiosGet': null
        };
    },
    created () {
        let accountCheckingUrl = 'GetAccountState';

        this.axiosInstance = axios.create(this.axiosConfig(true));

        this.axiosInstance.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, function (error) {
            let isCheckingAccountStatus = error.config ? error.config.url.toLowerCase().includes(accountCheckingUrl.toLowerCase()) : false;

            if (!isCheckingAccountStatus && error.response.status === 401) {
                $('#unauthorizedModal').modal('show');
            }

            return Promise.reject(error);
        });

        this.axiosGet = axios.create({
            'baseURL': apiPath,
            'withCredentials': true
        });

        this.axiosGet.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, function (error) {
            let isCheckingAccountStatus = error.config ? error.config.url.toLowerCase().includes(accountCheckingUrl.toLowerCase()) : false;

            if (!isCheckingAccountStatus && error.response.status === 401) {
                $('#unauthorizedModal').modal('show');
            }

            return Promise.reject(error);
        });
    }
};
