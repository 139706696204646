import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import user from './modules/user';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
    'plugins': [createPersistedState({
        'paths': ['app']
    })],
    'state': {
        'isPublic': true
    },
    'mutations': {
        setAppStatus (state, isPublic) {
            state.isPublic = isPublic;
        }
    },
    'actions': {

    },
    'modules': {
        app,
        user
    }
});

export default store;
