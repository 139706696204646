const user = {
    'state': {
        'userId': 0,
        'email': '',
        'userName': '',
        'fullName': '',
        'identityCardNumber': '',
        'userRole': 3,
        'allowShowTutorial': false,
        'addedSecurityPhrase': 0
    },
    'mutations': {
        setUserDetails (state, apiData) {
            state.userId = apiData.i;
            state.email = apiData.e;
            state.userName = apiData.un;
            state.fullName = apiData.fn;
            state.identityCardNumber = apiData.icn;
            state.userRole = apiData.r;
            state.allowShowTutorial = apiData.ast;
            state.addedSecurityPhrase = apiData.asp;
        },
        clearUserState (state) {
            state.userId = 0;
            state.email = '';
            state.userName = '';
            state.fullName = '';
            state.identityCardNumber = '';
            state.userRole = 3;
            state.allowShowTutorial = false;
            state.addedSecurityPhrase = 0;
        },
        addedSecurityPhrase (state) {
            state.addedSecurityPhrase = 1;
        },
        updateTutorial (state, allowShowTutorial) {
            state.allowShowTutorial = allowShowTutorial;
        }
    }
};

export default user;
