const app = {
    'state': {
        'pageTitle': 'InsureSavvy',
        'menuList': [],
        'sideMenuList': [],
        'isMobile': false,
        'displayMalay': false,
        'displayChinese': false
    },
    'mutations': {
        setPageTitle (state, title) {
            state.pageTitle = title;
        },
        setMenuList (state, list) {
            state.menuList = list;
        },
        setSideMenuList (state, list) {
            state.sideMenuList = list;
        },
        setIsMobile (state, isMobile) {
            state.isMobile = isMobile;
        },
        setDisplayMalay (state, displayMalay) {
            state.displayMalay = displayMalay;
        },
        setDisplayChinese (state, displayChinese) {
            state.displayChinese = displayChinese;
        }
    },
    'getters': {
        availableSideMenuList (state) {
            let list = [];

            state.menuList.forEach((item) => {
                if (item.meta && item.meta.group) {
                    let menuItem = {};

                    menuItem.title = item.meta ? item.meta.title : '';
                    menuItem.icon = item.meta ? item.meta.icon : '';

                    if (item.children) {
                        menuItem.children = [];

                        item.children.forEach((child) => {
                            if (child.meta && !child.meta.isHidden) {
                                let subMenuItem = {};
                                subMenuItem.title = child.meta ? child.meta.title : '';
                                subMenuItem.icon = child.meta ? child.meta.icon : '';
                                subMenuItem.route = child.name;
                                subMenuItem.public = child.meta ? !!child.meta.isPublic : false;
                                subMenuItem.admin = child.meta ? !!child.meta.isAdmin : false;
                                subMenuItem.company = child.meta ? !!child.meta.isCompany : false;
                                subMenuItem.affiliate = child.meta ? !!child.meta.isAffiliate : false;
                                subMenuItem.notForCompany = child.meta ? !!child.meta.isNotForCompany : false;
                                subMenuItem.titleForCompany = child.meta ? child.meta.titleForCompany : '';

                                menuItem.children.push(subMenuItem);
                            }
                        });
                    }

                    menuItem.public = menuItem.children ? menuItem.children.filter((child) => child.public).length > 0 : false;
                    menuItem.admin = menuItem.children ? menuItem.children.filter((child) => child.admin).length > 0 : false;
                    menuItem.company = menuItem.children ? menuItem.children.filter((child) => child.company).length > 0 : false;
                    menuItem.affiliate = menuItem.children ? menuItem.children.filter((child) => child.affiliate).length > 0 : false;
                    list.push(menuItem);
                } else { // is single
                    if (item.children) {
                        let menuItemChildren = [];

                        item.children.forEach((child) => {
                            if (!(child.meta && child.meta.isHidden)) {
                                let subMenuItem = {};
                                subMenuItem.title = child.meta ? child.meta.title : '';
                                subMenuItem.icon = child.meta ? child.meta.icon : '';
                                subMenuItem.route = child.name;
                                subMenuItem.public = child.meta ? !!child.meta.isPublic : false;
                                subMenuItem.admin = child.meta ? !!child.meta.isAdmin : false;
                                subMenuItem.company = child.meta ? !!child.meta.isCompany : false;
                                subMenuItem.affiliate = child.meta ? !!child.meta.isAffiliate : false;
                                subMenuItem.notForCompany = child.meta ? !!child.meta.isNotForCompany : false;
                                subMenuItem.titleForCompany = child.meta ? child.meta.titleForCompany : '';

                                menuItemChildren.push(subMenuItem);
                            }
                        });

                        list.push(...menuItemChildren);
                    } else {
                        let menuItem = {};
                        menuItem.title = item.meta ? item.meta.title : '';
                        menuItem.icon = item.meta ? item.meta.icon : '';
                        menuItem.route = item.name;
                        menuItem.public = item.meta ? !!item.meta.isPublic : false;
                        menuItem.admin = item.meta ? !!item.meta.isAdmin : false;
                        menuItem.company = item.meta ? !!item.meta.isCompany : false;
                        menuItem.affiliate = item.meta ? !!item.meta.isAffiliate : false;
                        menuItem.notForCompany = item.meta ? !!item.meta.isNotForCompany : false;
                        menuItem.titleForCompany = item.meta ? item.meta.titleForCompany : '';

                        list.push(menuItem);
                    }
                }
            });

            return list;
        }
    }
};

export default app;
